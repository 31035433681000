html {
    --primary-bg: #e8eaed;
    --primary-link: #0051f2;
    --primary-text: #4b4e54;
    --primary-button: #1a202c;
    --primary-button-hover: #101d36;
    --primary-input-bg: #c7ccd6;
    --primary-input-focus-bg: #a0a4ad;
    --primary-white: #f0f3f8;
}

@media (prefers-color-scheme: dark) {
    /* html {
        --primary-bg: #12171f;
        --primary-link: #0051f2;
        --primary-text: #ced3db;
        --primary-button: #5e6980;
        --primary-button-hover: #29509e;
        --primary-input-bg: #404245;
        --primary-input-focus-bg: #202f45;
    } */
}

html.dark {
    --primary-bg: #12171f;
    --primary-link: #0051f2;
    --primary-text: #ced3db;
    --primary-button: #5e6980;
    --primary-button-hover: #29509e;
    --primary-input-bg: #404245;
    --primary-input-focus-bg: #202f45;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
