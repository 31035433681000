.timer:hover {
    cursor: pointer;
}

.time-button {
    height: 5vmin;
    background-color: var(--primary-button);
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 15px;
    font-weight: bold;
    color: var(--primary-white);
    border: none;
    margin: 5px;
    border-radius: 20px;
}

.time-button:hover {
    background-color: var(--primary-button-hover);
}

label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    /* display: inline-block; */
    width: 30vw;
    margin: 2px;
    background-color: var(--primary-input-bg);
    border: 0px;
    padding: 10px;
    border-radius: 17px;
    text-align: center;
    color: var(--primary-text);
}

input:active,
input:focus {
    outline: none;
    background-color: var(--primary-input-focus-bg);
}
