.App {
    text-align: center;
    background-color: var(--primary-bg);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    font-size: calc(10px + 1vmin);
    color: var(--primary-text);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    display: flex;
    height: 40px;
    justify-content: flex-start;
    align-items: flex-start;
}

.App-header a {
    text-decoration: none;
    color: var(--primary-text);
    font-size: calc(12px + 0.5vmin);
    margin: 5px;
}

.App-link {
    color: var(--primary-link);
    text-decoration: none;
}
